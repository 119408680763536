import { UserModel } from './user.model';
import { TalBookModel } from './talbook.model';
import { SupplierModel } from './supplier.model';
import { SeasonModel } from './season.model';

export class ReceiptModel {
  id: number;
  talbook_sheet: string;
  original_receiver_id: number;
  original_receiver: UserModel;
  current_receiver_id: number;
  current_receiver_name: string;
  original_receiver_name: string;
  talbook_id: number;
  talbook: TalBookModel;
  supplier_id: number;
  supplier: SupplierModel;
  collected_date: any;
  receipt: any;
  receipt_content: any;
  ticket_type_cd: number;
  season_id: number;
  season: SeasonModel;
  comments: string;
  synced: boolean;
  used: boolean;
  completed: boolean;
  nulled: boolean;
  season_name: string;
}